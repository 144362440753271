
import DAYJS_IMPORT_NAME from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import weekYear from "dayjs/plugin/weekYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isMoment from "dayjs/plugin/isMoment";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
DAYJS_IMPORT_NAME.extend(isSameOrBefore);
DAYJS_IMPORT_NAME.extend(isSameOrAfter);
DAYJS_IMPORT_NAME.extend(advancedFormat);
DAYJS_IMPORT_NAME.extend(customParseFormat);
DAYJS_IMPORT_NAME.extend(weekday);
DAYJS_IMPORT_NAME.extend(weekYear);
DAYJS_IMPORT_NAME.extend(weekOfYear);
DAYJS_IMPORT_NAME.extend(isMoment);
DAYJS_IMPORT_NAME.extend(localeData);
DAYJS_IMPORT_NAME.extend(localizedFormat);
import localePlugin from "unplugin-moment-to-dayjs/plugins/locale";
DAYJS_IMPORT_NAME.extend(localePlugin);